.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-container {
  font-family: var(--primary-ff);
  text-align: center;
  background-color: #fff;
  padding: 30px;
  margin-top: 150px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-container h1 {
  font-size: 5rem;
  color: var(--secondary-color);
  padding: 15px;
}

.error-container p {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
  padding: 15px;
  margin-bottom: 50px;
}

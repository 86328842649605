.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
}

.modal {
  position: fixed;
  width: 80%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 50px;
  z-index: 10000;
}

.container {
  border-radius: 15px;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  position: relative;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

#btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.nav {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 1rem;
  margin: 0 -2rem 2rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.nav h3 {
  font-weight: bold;
}

/* 
.nav:before,
.nav:after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  border: 0.75rem solid transparent;
  border-bottom: 0.75rem solid #f2f0f0;
  transform-origin: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.nav:before {
  left: 0;
  transform: translateY(-0.45rem) rotate(135deg) translateX(-0.4rem);
}

.nav:after {
  right: 0;
  transform: translateY(-0.45rem) rotate(-135deg) translateX(0.4rem);
} */

.list {
  height: 600px;
  overflow-y: scroll;
}

@media (max-width: 1200px) {
  .nav {
    padding: 0.5rem 0rem;
    margin: 0 -2rem 2rem;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .modal {
    width: 95%;
    height: 60vh;
    margin-top: 10px;
  }

  .list {
    height: 60vh;
    overflow-y: scroll;
  }
}

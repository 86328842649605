/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-item {
  width: 100%;
  margin-bottom: 15px;
}

.features .nav-item:last-child {
  margin-bottom: 0;
}

.features .nav-link {
  color: var(--heading-color);
  border: 0;
  padding: 30px;
  transition: 0.3s;
  border-radius: 10px;
  display: flex;
}

.features .nav-link i {
  background-color: var(--surface-color);
  color: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 48px;
  height: 48px;
  font-size: 22px;
  flex-shrink: 0;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.features .nav-link h4 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 10px 0;
  transition: 0.3s;
}

.features .nav-link p {
  font-size: 18px;
  cursor: default;
  margin: 0;
}

.features .nav-link:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 96%);
}

.features .nav-link.active {
  background: color-mix(in srgb, var(--accent-color), transparent 96%);
  color: var(--default-color);
}

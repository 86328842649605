/*** Service ***/
.service-item {
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
}

.service-item .service-icon {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-item .service-icon img {
  max-width: 60px;
  max-height: 60px;
}

.service-item a.btn {
  background-color: #eee;
  color: var(--heading-color);
}

.service-item a.btn:hover {
  color: #fff;
  background: var(--accent-color);
  border-color: var(--accent-color);
}

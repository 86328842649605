/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.hero {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding: 120px 0 80px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero .bgImage {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.hero:before {
  content: '';
  background: color-mix(in srgb, var(--background-color), transparent 30%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
}

.hero h2 {
  margin: 0;
  font-size: 56px;
  margin-bottom: 80px;
  font-weight: 700;
  font-family: var(--nav-font);
  text-transform: uppercase;
}

.hero h2 span {
  color: var(--accent-color);
}

@media (max-width: 1200px) {
  .hero h2 {
    margin-top: 70px;
  }
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
  }

  .hero p {
    font-size: 18px;
  }
}

.hero .content {
  margin-top: 40px;
}

.hero .content .why-box {
  color: #111;
  background: var(--heading-color);
  padding: 30px;
  border-radius: 4px;
}

.hero .content .why-box h3 {
  color: #111;
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 30px;
}

.hero .content .why-box ul {
  padding: 0;
}

.hero .content .why-box ul li {
  display: flex;
  list-style: none;
}

.hero .content .why-box ul li p {
  font-size: 16px;
}

.hero .content .why-box .more-btn {
  color: #fff;
  background: #56b8e6;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.hero .content .why-box .more-btn i {
  font-size: 14px;
}

.hero .content .why-box .more-btn:hover {
  background: var(--surface-color);
  color: #fff;
}

.hero .content .icon-box-hero {
  text-align: center;
  border-radius: 10px;
  background: var(--surface-color);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  width: 100%;
}

.hero .content .icon-box-hero i {
  font-size: 40px;
  color: var(--accent-color);
}

.hero .content .icon-box-hero h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 20px 0;
}

.hero .content .icon-box-hero p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 0%);
}

.hero .content .icon-box-hero {
  text-align: center;
  border-radius: 10px;
  background: color-mix(in srgb, var(--surface-color), transparent 20%);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  width: 100%;
}

.hero .content .icon-box-hero i {
  font-size: 40px;
  color: var(--accent-color);
}

.hero .content .icon-box-hero h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 20px 0;
}

.hero .content .icon-box-hero p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.hero .content .icon-box-hero .more-btn {
  color: #fff;
  font-size: 15px;
  background: #56b8e6;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 500;
  transition: all ease-in-out 0.4s;
}

.hero .content .icon-box-hero .more-btn i {
  font-size: 14px;
  color: #fff;
}

.hero .content .icon-box-hero .more-btn:hover {
  background: var(--surface-color);
  color: #fff;
}

.hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: var(--accent-color);
}

.hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  line-height: 26px;
}

.hero .icon-box h3 a {
  color: #fff;
  cursor: default;
  transition: ease-in-out 0.3s;
}

.hero .icon-box:hover {
  border-color: var(--accent-color);
}

.hero .icon-box:hover h3 a {
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .hero h2 {
    font-size: 32px;
  }

  .hero p {
    font-size: 18px;
  }
}

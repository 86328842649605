.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  background: none;
  color: #0f1923;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.15s ease;
  position: relative;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  25% {
    -webkit-transform: scale(1.06);
    -ms-transform: scale(1.06);
    transform: scale(1.06);
  }

  60% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.button:hover {
  transform: scale(1.01);
}

/* 
.button {
  animation: breathing 5s ease-in-out infinite normal;
} */

@keyframes clickMove {
  0% {
    transform: translateY(25px);
  }

  100% {
    transform: translateY(0px);
  }
}

.clickIcon {
  position: absolute;
  bottom: -5px;
  right: 38%;
  width: 60px;
  height: 60px;
  z-index: 500;
  animation: 3s clickMove infinite;
}

@media (max-width: 600px) {
  .clickIcon {
    position: absolute;
    bottom: -5px;
    right: 10%;
  }
}

.button::before,
.button::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  height: calc(50% - 5px);
  border: 1px solid #7d8082;
  transition: all 0.15s ease;
}

.button::before {
  top: 0;
  border-bottom-width: 0;
}

.button::after {
  bottom: 0;
  border-top-width: 0;
}

.button:active,
.button:focus {
  outline: none;
}

.button:active::before,
.button:active::after {
  right: 3px;
  left: 3px;
}

.button:active::before {
  top: 3px;
}

.button:active::after {
  bottom: 3px;
}

.button_lg {
  position: relative;
  display: block;
  padding: 20px 20px;
  color: #fff;
  background-color: var(--accent-color);
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 1px transparent;
  transition: all 0.2s;
}

/* @media (max-width: 800px) {
  .button_lg {
    background-color: #e01a4f;
    }
    } */

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(224, 26, 79, 0.7);
    /* box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); */
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.button_text {
  position: relative;
  text-transform: none;
  font-size: 23px;
}

.button_text small {
  animation: pulse 2s infinite;
  background-color: rgb(224, 26, 79);
  /*background-color: #fff;
  color: #000; */
  padding: 10px 20px;
  border-radius: 100px;
}

/* @media (max-width: 800px) {
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(224, 26, 79, 0.7);
    }

    70% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

  .button_text small {
    background-color: rgb(224, 26, 79);
    color: #fff;
  }
} */

.button:hover {
  color: #0f1923;
}

.button:hover .button_lg::after {
  background-color: #fff;
}

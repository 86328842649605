.btn-getstarted,
.btn-getstarted:focus {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  color: var(--contrast-color);
  background: var(--accent-color);
  border-color: var(--accent-color);
  font-size: 17px;
  padding: 10px 25px;
  border: 2px solid #fff;
  border-radius: 50px;
  transition: 0.3s;
  z-index: 200;
}

.btn-getstarted:hover,
.btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

@media (max-width: 1200px) {
  .btn-getstarted,
  .btn-getstarted:focus {
    display: block;
    order: 2;
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9000;
}

.modal {
  position: fixed;
  width: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 50px;
  z-index: 10000;
}

.container {
  border-radius: 15px;
  padding: 2rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nav {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;

  margin: 0 -2rem 2rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.nav h3 {
  font-weight: bold;
}

/* 
.nav:before,
.nav:after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  border: 0.75rem solid transparent;
  border-bottom: 0.75rem solid #f2f0f0;
  transform-origin: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.nav:before {
  left: 0;
  transform: translateY(-0.45rem) rotate(135deg) translateX(-0.4rem);
}

.nav:after {
  right: 0;
  transform: translateY(-0.45rem) rotate(-135deg) translateX(0.4rem);
} */

@media (max-width: 1200px) {
  .modal {
    width: 95%;
    overflow: hidden;
  }

  .video {
    width: 100vw;
  }
}

.button {
  --width: 150px;
  --height: 50px;
  --tooltip-height: 35px;
  --tooltip-width: 90px;
  --gap-between-tooltip-to-button: 10px;
  --button-color: var(--accent-color);
  --tooltip-color: #fff;
  width: var(--width);
  height: var(--height);
  background: var(--button-color);
  position: relative;
  text-align: center;
  border-radius: 0.45em;
  margin-top: 10px;
  font-family: 'Arial';
  cursor: pointer;
  transition: background 0.3s;
}
/* 
.button::before {
  position: absolute;
  content: attr(data-tooltip);
  width: var(--tooltip-width);
  height: var(--tooltip-height);
  background-color: var(--tooltip-color);
  font-size: 0.9rem;
  color: #111;
  border-radius: 0.25em;
  line-height: var(--tooltip-height);
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
  left: calc(50% - var(--tooltip-width) / 2);
}

.button::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: var(--tooltip-color);
  left: calc(50% - 10px);
  bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
}

.button::after,
.button::before {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s;
} */

.text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-wrapper,
.text,
.icon {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  color: #fff;
}

.text {
  top: 0;
}

.text,
.icon {
  transition: top 0.5s;
}

.icon {
  color: #fff;
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  background: #1b2f45;
}

.button:hover .text {
  top: -100%;
}

.button:hover .icon {
  top: 0;
}

.button:hover:before,
.button:hover:after {
  opacity: 1;
  visibility: visible;
}

.button:hover:after {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
}

.button:hover:before {
  bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
}

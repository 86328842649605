/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.call-to-action {
  padding: 80px 0;
  position: relative;
  clip-path: inset(0);
}

.call-to-action img {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.call-to-action:before {
  content: '';
  background: color-mix(in srgb, var(--background-color), transparent 50%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.call-to-action .container {
  position: relative;
  z-index: 3;
}

.call-to-action h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--default-color);
}

.call-to-action p {
  color: var(--default-color);
}

.call-to-action .cta-btn {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
}

.call-to-action .cta-btn:hover {
  background: color-mix(in srgb, var(--accent-color) 90%, black 15%);
}

/* Counter */

.counter-box {
  display: block;
  /* background: #f6f6f6; */
  padding: 40px 20px 37px;
  text-align: center;
}

.counter-box p {
  margin: 5px 0 0;
  padding: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #fff;
}

.counter {
  display: block;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  line-height: 28px;
}

/* .counter-box.colored {
  background: #3acf87;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
} */

.header {
  color: #fff;
  width: 100vw;
  transition: all 0.5s;
  z-index: 997;
}

.header.pathname {
  background-color: var(--heading-color);
}

.header.isScrolled {
  background-color: var(--heading-color);
}

@media (max-width: 1000px) {
  .header {
    background-color: var(--heading-color);
    padding-inline: 20px;
  }
}

.header .topbar {
  background-color: transparent;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 17px;
  transition: all 0.5s;
}

.header .topbar .contact-info {
  width: 100%;
  display: flex;
  align-items: center;
}

.header .topbar .contact-info i {
  font-style: normal;
  color: var(--accent-color);
}

.header .topbar .contact-info i a,
.header .topbar .contact-info i span {
  padding-left: 2px;
  color: #fff;
}

@media (max-width: 765px) {
  .header .topbar .contact-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .header .topbar .contact-info {
    display: flex;
    justify-content: space-between;
  }
  .header .topbar .contact-info i a,
  .header .topbar .contact-info i span {
    font-size: 11px;
  }
}

@media (max-width: 433px) {
  .header .topbar .contact-info {
    display: flex;
    justify-content: space-between;
  }

  .header .topbar .contact-info i,
  .header .topbar .contact-info i a,
  .header .topbar .contact-info i span {
    font-size: 9px;
  }
}

.header .topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

.header .topbar .contact-info i a:hover {
  color: var(--contrast-color);
  text-decoration: underline;
}

.header .topbar .social-links a {
  color: color-mix(in srgb, var(--accent-color), transparent 40%);
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}

.header .topbar .social-links a:hover {
  color: var(--accent-color);
}

/* Navbar */

.header .navbar {
  width: 100%;
  background-color: transparent;
}

.header .navbar div a {
  color: #fff;
}

.header .navbar div .logo img {
  max-width: 200px;
}

.header .navbar div .collapse .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 17px;
  transition: all 0.5s;
}

.header .navbar div .collapse .navbar-nav .nav-item .nav-link:hover {
  color: var(--accent-color);
}

.header .navbar div .collapse .navbar-nav .nav-item .nav-link.active {
  color: var(--accent-color);
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  color: var(--contrast-color);
  background: var(--accent-color);
  border-color: var(--accent-color);
  font-size: 17px;
  padding: 8px 25px;
  margin: 0 0 0 30px;
  border-radius: 50px;
  transition: 0.3s;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  color: var(--contrast-color);
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
}

@media (max-width: 1200px) {
  .header .btn-getstarted {
    order: 2;
    margin: 0 15px 0 0;
    padding: 6px 15px;
  }
}

@media (max-width: 1200px) {
  .header .btn-getstarted {
    display: none;
  }
}

.header .nav-item .dropdown-menu .dropdown-item {
  color: #111;
}

.dropdown-item.active {
  font-weight: bold;
  background-color: #eee;
  color: #fff;
}

@media (min-width: 1200px) {
  .dropdown:hover > .dropdown-menu {
    display: block;
    margin-top: 0;
  }
}

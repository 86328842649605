.container .row img {
  max-width: 100%;
}

.demo {
  background: #fff;
  max-width: 900px;
  margin: auto;
}

.carousel-caption {
  position: initial;
  z-index: 10;
  padding: 1rem 4rem;
  color: rgba(78, 77, 77, 0.856);
  text-align: left;
  font-size: 1.2rem;

  font-style: italic;
  font-weight: bold;
  line-height: 2rem;
}
@media (max-width: 767px) {
  .carousel-caption {
    position: initial;
    z-index: 10;
    padding: 3rem 4rem;
    color: rgba(78, 77, 77, 0.856);
    text-align: left;
    font-size: 16px;

    font-style: italic;
    font-weight: bold;
    line-height: 1.5rem;
  }
}

.carousel-caption .item h3 {
  font-weight: bold;
}

.carousel-caption .item h4 {
  opacity: 0.5;
}

.carousel-caption .item h3 {
  font-weight: bold;
}

.carousel-caption .item {
  font-weight: 400;
}

.carousel-control-prev i,
.carousel-control-next i {
  background-color: var(--heading-color);
  padding: 1.4rem;
}

@media (max-width: 767px) {
  .carousel-control-prev i,
  .carousel-control-next i {
    padding: 0.8rem;
  }
}
.carousel-control-prev {
  justify-content: flex-start;
}

.carousel-control-next {
  justify-content: flex-end;
}
.carousel-control-prev,
.carousel-control-next {
  transition: none;
  opacity: unset;
}

/*** Footer ***/
.footer {
  color: #a7a8b4;
  background-color: var(--heading-color);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #a7a8b4;
  font-weight: normal;
  text-decoration: none;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #a7a8b4;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .btn.btn-square {
  color: #a7a8b4;
  border: 1px solid#A7A8B4;
}

.footer .btn.btn-square:hover {
  color: var(--secondary);
  border-color: var(--light);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--secondary);
}

.footer .copyright a:hover {
  color: #ffffff;
}

.footer .social-links a {
  color: var(--contrast-color);
  background-color: var(--accent-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  font-size: 16px;
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:nth-child(1) {
  background-color: #8f5db7;
}
.footer .social-links a:nth-child(2) {
  background-color: #25d366;
}
.footer .social-links a:nth-child(3) {
  background: linear-gradient(45deg, #f09433, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888);
}
.footer .social-links a:nth-child(4) {
  background-color: #3b5998;
}
.footer .social-links a:nth-child(5) {
  background-color: #111;
}

.footer .social-links a:hover {
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--accent-color) 90%, black 15%);
}

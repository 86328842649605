.modal {
  margin-top: 130px;
  z-index: 10000;
}

.container {
  border-radius: 15px;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
}

.nav {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  margin: 0 -2rem 2rem;
  position: relative;
}

.nav h3 {
  font-weight: bold;
}

/* 
.nav:before,
.nav:after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  border: 0.75rem solid transparent;
  border-bottom: 0.75rem solid #f2f0f0;
  transform-origin: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: -1;
}

.nav:before {
  left: 0;
  transform: translateY(-0.45rem) rotate(135deg) translateX(-0.4rem);
}

.nav:after {
  right: 0;
  transform: translateY(-0.45rem) rotate(-135deg) translateX(0.4rem);
} */

.list .num {
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 0.25s;
}

.list .num h3 {
  position: relative;
  color: #3d3d3d;
  font-size: 1rem;
  transition: 0.25s;
}

.list .num:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.list .num:hover::before {
  opacity: 0.2;
}

@media (max-width: 1200px) {
  .modal {
    width: 95%;
    height: 90%;
  }

  .list .num {
    padding: 0.5rem 0rem;
  }
}
